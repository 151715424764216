@import "../global/variables";

.error-page{
  background-image:url('/images/404_bg.jpg');
  background-size: cover;
  padding: 50px 0;
  @include breakpoint(SM){
    padding: 100px;
  }
  @include breakpoint(MD){
    padding: 150px;
  }
  .inner{
    padding: 0 15px;
    margin: 0 auto;
    width: 90%;

    @include breakpoint(SM){
      width: 100%;
    }
  }
  h1{
    @include font(script);
    letter-spacing: 0;
    color:$dark-purple;
    margin: 0;
    font-size: 60px;
  }
  h2{
    @include font(script);
    letter-spacing: 0;
    color:$dark-purple;
    margin: 0;
    font-size: 27px;
    margin-bottom: 20px;
    @include breakpoint(SM){
      font-size: 33px;
      line-height: 44px;
    }
  }
  p{
    margin: 10px 0;
    @include font(light);
    line-height: 32px;
    letter-spacing: 0;
    font-size: 16px;
    @include breakpoint(SM){
      font-size: 25px;
    }
  }
  .divider-points{
    margin: 30px auto;
  }
  button {
    margin-bottom: 10px;
  }
}
